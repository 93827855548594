
const Technicians = (): React.ReactElement => {
  const testAPI = () => {
    console.log("testing api")
    fetch('/api/shopmonkey/users')
        .then(response => response.json())
        .then(data => {
            console.log(data)
        })
  }

  return (
      <div className="App">
        <header className="App-header">
          Plan B Supply Technicians
        </header>
        <button onClick={testAPI}>API Test</button>
      </div>
  );
};

export default Technicians;
