import React, {useEffect, useState, ReactElement} from 'react';
import {Order} from "../models/order";
import {
    Box,
    ListItemButton,
    ListItem,
    ListItemText,
    TableCell,
    TableRow,
    TableContainer,
    Table,
    TableBody,
    TableHead,
    TableSortLabel,
    Typography
} from "@mui/material";

// @ts-ignore
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import {ServiceLabor} from "../models/serviceLabor";


const Orders = (): ReactElement => {
    const [loading, setLoading] = useState(true)
    const [order, setOrder] = useState<Order | null>(null)
    const [orders, setOrders] = useState<Order[] | null>(null)
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const [services, setServices] = useState<ServiceLabor[] | null>(null)

    useEffect(() => {
        if (loading) {
            setLoading(false)

            fetch('/api/shopmonkey/orders')
                .then(response => response.json())
                .then(data => {
                    setOrders(data)
            })
        }
    }, [loading, setLoading, orders, setOrders])


    const handleListItemClick = (
        index: number,
        order: Order
    ) => {
        setOrder(order)
        setSelectedIndex(index);
        const url = `/api/shopmonkey/orders/${order.id}/services`
        fetch(url)
            .then(response => response.json())
            .then(data => {
                setServices(data)
            })
    };

    const computeBackgroundColor = (order: Order) => {
        if (order.isPaid) {
            return 'background-green'
        }
        if (!order.updateDate) {
            return 'background-grey'
        }

        const updateDate = Date.parse(order.updateDate)
        const now = new Date();
        const thirtyDaysInMs = 30 * 24 * 60 * 60 * 1000;
        const timeDiffInMs = now.getTime() - updateDate;
        if (timeDiffInMs >= thirtyDaysInMs) {
            return 'background-red'
        }

        const fourteenDaysInMs = 14 * 24 * 60 * 60 * 1000;
        if (timeDiffInMs >= fourteenDaysInMs) {
            return 'background-yellow'
        }

        return 'background-grey'
    }

    function renderRow(props: ListChildComponentProps) {
        const { index, style } = props;

        if (!orders) {
            return <ListItem></ListItem>
        }

        const data = orders[index]

        return (
            <Box>
                <ListItem
                    style={style}
                    key={index}
                    component="div"
                    className={computeBackgroundColor(data)}
                    disablePadding
                >
                    <ListItemButton
                        selected={selectedIndex === index}
                        onClick={(event) => handleListItemClick(index, data)}
                    >
                        <ListItemText>
                            {data?.name}
                        </ ListItemText>
                    </ListItemButton>
                </ListItem>
            </Box>
        );
    }

    const ServiceTableHead = () => {
        return <TableHead>
            <TableRow>
                <TableCell>
                    <TableSortLabel>
                        <Typography variant={"h5"}>
                            Outstanding Task
                        </Typography>
                    </TableSortLabel>
                </TableCell>
                <TableCell>
                    <TableSortLabel>
                        <Typography variant={"h5"}>
                            Assigned Tech
                        </Typography>
                    </TableSortLabel>
                </TableCell>
            </TableRow>
        </TableHead>
    }

    const ServiceLaborRows = () => {
        if (!services || services.length === 0) {
            return
        }

        return services.map((data, index)  => {
            const labelId = `services-table-checkbox-${index}`;
            if (data.isCompleted) {
                return
            }
            return (
                <TableRow
                    hover
                    tabIndex={-1}
                    key={data.laborID}
                    sx={{ cursor: 'pointer' }}
                >
                    <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                    >
                        {data.serviceName} - {data.laborName}
                    </TableCell>
                    <TableCell>{data.technicianName}</TableCell>
                </TableRow>
            );
        })
    }



    return (
        <Box sx={{ width: '100%', display: "flex" }} id="test">
            <FixedSizeList
                aria-label="orders list"
                itemCount={orders?.length || 0}
                height={window.innerHeight - 65}
                width={300}
                itemSize={46}
            >
                {renderRow}
            </FixedSizeList>


            <Box sx={{width:"100%", padding:"1em"}}  >
                {order && <Box>
                        <Typography variant="h4">Order: {order?.name}</Typography>
                        <TableContainer>
                            <Table
                                aria-labelledby="tableTitle"
                            >
                                {ServiceTableHead()}
                                <TableBody>
                                    {ServiceLaborRows()}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                }
            </Box>
        </Box>
  );
};

export default Orders;
