import React from 'react';
import {
    AppBar,
    Toolbar,
    Typography,
    MenuItem
} from '@mui/material'
import {Route, Routes, useNavigate, useLocation} from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import Orders from './pages/Orders';
import Technicians from "./pages/Technicians";

const App = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const menuItemSelected = (want: string) => {
        if (location.pathname === want) {
            return "menu-item-selected"
        }

        return "menu-item"
    }

    const navigateTo = (location: string) => {
        navigate(location)
    }

    return (
      <>
          <AppBar
              position="static"
          >
              <Toolbar>
                  <img
                      onClick={() => navigateTo("/")}
                      className="header-logo"
                      alt="Plan B Supply logo"
                      src="/logo-web.png"
                  />
                  <MenuItem
                      className={menuItemSelected("/orders")}
                      onClick={() => navigateTo("/orders")}
                  >
                      <Typography textAlign="center">Orders</Typography>
                  </MenuItem>
                  <MenuItem
                      className={menuItemSelected("/technicians")}
                      onClick={() => navigateTo("/technicians")}
                  >
                      <Typography textAlign="center">Technicians</Typography>
                  </MenuItem>
              </Toolbar>
          </AppBar>
          <Routes>
              <Route path="" element={<Home />} />
              <Route path="/" element={<Home />} />
              <Route path="/orders" element={ <Orders />} />
              <Route path="/technicians" element={ <Technicians />} />
          </Routes>
      </>
  );
}

export default App;
